import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./styles/App.css";

import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";

import AuthService from "./services/AuthService";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencil, faPlusCircle, faTimesCircle, faBars, faTable, faEye, faCheck, faUpload, faTrash, faChevronCircleUp, faCheckCircle, faSortDown as faSortDownSolid, faSortUp as faSortUpSolid } from "@fortawesome/pro-solid-svg-icons";
import { faSignIn, faSignOut } from "@fortawesome/pro-light-svg-icons";
import { faSortDown as faSortDownDuo } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faPencil,
  faPlusCircle, 
  faTimesCircle,
  faBars,
  faTable,
  faEye,
  faChevronCircleUp,
  faCheck,
  faCheckCircle,
  faUpload,
  faTrash,
  faSignIn,
  faSignOut,
  faSortDownDuo,
  faSortDownSolid,
  faSortUpSolid
);

const Auth = new AuthService();

const MESSAGES = [
  "Sorry, we had trouble signing you in. Please check your entry and try again",
  "Sorry, there have been too many failed login attempts. Please wait a few minutes before trying again.",
  "Sorry, we couldn't find your username. Please register to request access."
];

const TOAST_ID = "toast-error";

/** Launch app */
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: Auth.loggedIn(),
      isLoading: false
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleDiageoLogin = this.handleDiageoLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  /** Handle user login */
  handleLogin(username, password, referrer) {
    this.setState({ isLoading: true });

    Auth.login(username, password)
      .then(res => {
        this.setState({ isLoggedIn: true, isLoading: false });
        this.props.history.push(referrer ? referrer : '/');
      })
      .catch(err => {
        let message = "";

        if (err && err.status === 429) {
          message = MESSAGES[1];
        } else {
          message = `${MESSAGES[0]}. ${err && err.data.message && 'Message: ' + err.data.message[0].messages[0].message}`;
        }

        this.setState({ isLoggedIn: false, isLoading: false });
        toast(message, { className: "toast-red", type: toast.TYPE.ERROR, toastId: TOAST_ID });
      });
  }

  /** Handle Diageo user login */
  handleDiageoLogin(username) {
    Auth.loginDiageoUser(username)
      .then(res => {
        this.setState({ isLoggedIn: true, isLoading: false });
        this.props.history.push('/');
      })
      .catch(err => {
        this.setState({ isLoggedIn: false, isLoading: false }); 
        toast( MESSAGES[2], { className: "toast-red", type: toast.TYPE.ERROR, toastId: TOAST_ID });

        this.props.history.push('/register');
      });
  }

  /** Handle user logout */
  handleLogout() {
    Auth.logout();
    this.setState({ isLoggedIn: false });
  }

  render() {
    return (
      <div className="app section">
        <Header
          isLoggedIn={this.state.isLoggedIn}
          onLogout={this.handleLogout}
        />
        <div className="container is-fluid">
          <ToastContainer
            toastClassName="toast-style"
            bodyClassName="toast-body"
          />
          <Main
            onLogin={this.handleLogin}
            onDiageoLogin={this.handleDiageoLogin}
            isLoading={this.state.isLoading}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
