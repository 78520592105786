import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import navItems from "../helpers/nav";

import "./styles/Nav.css";

import NavItem from "./elements/NavItem";

const Nav = props => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [buttonText, setButtonText] = useState('Login');
  const [buttonUrl, setButtonUrl] = useState('Login');
  const [buttonIcon, setButtonIcon] = useState('sign-in');

  useEffect(() => {
    if (props.isLoggedIn) {
      setLoggedIn(true);
      setButtonText('Logout');
      setButtonUrl('/');
      setButtonIcon('sign-out');
    } else {
      setLoggedIn(false);
      setButtonText('Login');
      setButtonUrl('/login');
      setButtonIcon('sign-in');
    }
  });

  const toggleLoginLogout = (e) => {
    setButtonText(e.target.text === 'Logout' ? 'Login' : 'Logout');
  }

  return (
    <div className="main-nav" id="nav" role="navigation">
      <div className="nav-list">
        <ul>
          {navItems.map((item, i) => {
            return (
              <NavItem
                key={i}
                path={item.path}
                title={item.title}
              />
            );
          })}
        </ul>
      </div>
      <div className="login-logout">
        <Link
          to={buttonUrl}
          onClick={loggedIn ? (e) => { props.onLogout(); setLoggedIn(false); toggleLoginLogout(e); } : () => {}}
        >
          <FontAwesomeIcon icon={['fal', `${buttonIcon}`]} fixedWidth size="3x" />
          {buttonText}
        </Link>
      </div>
    </div>
  );
}

export default Nav;
