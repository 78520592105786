import React from "react";
import {
  NavLink
} from "react-router-dom";

const NavItem = props => {
  return (
    <li className={props.className} key={props.index}>
      <NavLink
        exact
        to={props.path}
        activeStyle={{
          color: "#b9975b"
        }}
      >
        {props.title}
      </NavLink>
    </li>
  );
}

export default NavItem;
