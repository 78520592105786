let items = [
  { title: "About ICE", path: "/", dropdown: null },
  { title: "Scorecards", path: "/projects", dropdown: null },
  { title: "Workstreams", path: "/workstreams", dropdown: null },
  { title: "Reports", path: "/reports", dropdown: null },
  { title: "Contact", path: "/contact", dropdown: null },
  { title: "Register", path: "/register", dropdown: null }
];

export default items;
