import React from "react";
import logo from "../assets/diageo-logo.png";
import "./styles/Header.css";
import Nav from "./Nav";

const Header = props => {
  return (
    <header className="app-header">
      <a href="/">
        <img src={logo} alt="Diageo" />
        <h1>Ice</h1>
      </a>
      <Nav isLoggedIn={props.isLoggedIn} onLogout={props.onLogout} />
    </header>
  );
}

export default Header;
