let params = {
  website: "https://ice.1000heads.net",
  api: "https://api.ice.1000heads.net",
  permissions: {
    all: ["root", "diageo_staff", "authenticated", "agency", "analyst"],
    agency: ["root", "diageo_staff", "agency"],
    analyst: ["root", "analyst"],
    brand: ["root", "diageo_staff"],
    admin: ["root"]
  },
  access: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IkdpNzZFaWVGTFhXeWg0QXgi.JFA3q8zBG6Cyir32JLHvnPkTl2XH0HmlUzQMqyS0pfY",
  secret: "ice-horizon-platform-diageo"
};

export default params;
