import React, { Suspense, lazy } from "react";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RotateSpinLoader } from "react-css-loaders";

import "./styles/Main.css";

const ProtectedRoute = lazy(() => import("../components/ProtectedRoute"));

const About = lazy(() => import('../pages/About'));
const Login = lazy(() => import('../pages/Login'));
const Contact = lazy(() => import('../pages/Contact'));
const Register = lazy(() => import('../pages/Register'));
const Projects = lazy(() => import('../pages/Projects'));
const Workstreams = lazy(() => import('../pages/Workstreams'));
const Reports = lazy(() => import('../pages/Reports'));
const Scorecard = lazy(() => import('../pages/Scorecard'));
const CreateScorecard = lazy(() => import('../pages/CreateScorecard'));
const EditScorecard = lazy(() => import('../pages/EditScorecard'));
const MediaItems = lazy(() => import('../pages/MediaItems'));
const NotFound = lazy(() => import('../pages/NotFound'));
const NotAuthorised = lazy(() => import('../pages/NotAuthorised'));
const Thanks = lazy(() => import('../pages/Thanks'));
const DiageoAuth = lazy(() => import('../auth/DiageoAuth'));

function ScorecardPaths() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}/edit/:id`} component={(props) => <EditScorecard {...props} />} />
      <ProtectedRoute path={`${path}/create`} component={CreateScorecard} />
      <ProtectedRoute path={`${path}/:id`} component={(props) => <Scorecard {...props} />} />
    </Switch>
  )
}

const Main = (props) => {
  return (
    <Suspense fallback={<RotateSpinLoader size="5" />}>
      <Switch>
        <Route exact path="/" component={About} />
        <ProtectedRoute path="/projects" component={Projects} />
        <ProtectedRoute path="/workstreams/:id?" component={(props) => <Workstreams {...props} />} />
        <ProtectedRoute path="/media-items/:type/:id" component={(props) => <MediaItems {...props} />} />
        <ProtectedRoute path="/scorecard">
          <ScorecardPaths />
        </ProtectedRoute>
        <ProtectedRoute path="/reports" component={Reports} />
        <Route path="/contact" component={Contact} />
        <Route path="/register" component={Register} />
        <Route path="/thanks" component={Thanks} />
        <Route path="/not-authorised" component={NotAuthorised} />
        <Route path="/login" component={() => <Login onLogin={props.onLogin} isLoading={props.isLoading} />} />
        <Route path="/diageo_auth/:user" component={() => <DiageoAuth onDiageoLogin={props.onDiageoLogin} isLoading={props.isLoading} />} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
};

export default Main;
