import axios from "axios";
import decode from "jwt-decode";
import jwt from "jwt-simple";
import params from "../helpers/params";

const authEndpoint = `${params.api}/auth/local`;

export default class AuthService {
  constructor(domain) {
    this.domain = domain || params.api; // API server domain
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login(username, password) {
    return axios
      .post(authEndpoint, {
        identifier: username,
        password: password
      })
      .then(res => {
        this.setLocalUserData(res.data.jwt, res.data.user.role.type, res.data.user.id, res.data.user.agency.id, res.data.user.brand ? res.data.user.brand.id : null);

        return Promise.resolve(res.data.user);
      })
      .catch(err => {
        return Promise.reject(err.response);
      });
  }

  loginDiageoUser(username) {
    return axios.get(`${params.api}/users-permissions/search/${username}`)
      .then(res => {
        let user = res.data[0];

        return axios.get(`${params.api}/users-permissions/roles`)
          .then((res) => {
            let role = res.data.roles.filter(role => role.id === user.role);

            return axios
              .post(authEndpoint, {
                identifier: 'diageo_auth',
                password: jwt.decode(params.access, params.secret)
              })
              .then(loggedUser => {
                this.setLocalUserData(loggedUser.data.jwt, role[0].type, user.id, user.agency.id, user.brand.id);

                return Promise.resolve(res.data.user);
              })
              .catch(err => {
                return Promise.reject(err.response);
              });
          })
          .catch(err => {
            return Promise.reject(err);
          });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from sessionStorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  logout() {
    // Clear user token and profile data from sessionStorage
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("agency");
    sessionStorage.removeItem("brand");
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired.
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setLocalUserData(jwt, role, user, agency, brand) {
    this.setToken(jwt);
    this.setRole(role);
    this.setUser(user);
    this.setBrand(brand ? brand : null);
    this.setAgency(agency ? agency : null);
  }

  setToken(idToken) {
    // Saves user token to sessionStorage
    sessionStorage.setItem("id_token", idToken);
  }

  getToken() {
    // Retrieves the user token from sessionStorage
    return sessionStorage.getItem("id_token");
  }

  setRole(role) {
    // Saves user role to sessionStorage
    sessionStorage.setItem("role", role);
  }

  getRole() {
    // Retrieves the user role from sessionStorage
    return sessionStorage.getItem("role");
  }

  setBrand(brand) {
    // Saves user brand to sessionStorage
    sessionStorage.setItem("brand", brand);
  }

  getBrand() {
    // Saves user brand to sessionStorage
    return sessionStorage.getItem("brand");
  }

  setUser(user) {
    // Saves user id to sessionStorage
    sessionStorage.setItem("user", user);
  }

  getUser() {
    // Retrieves the user id from sessionStorage
    return sessionStorage.getItem("user");
  }

  setAgency(agency) {
    // Saves user agency to sessionStorage
    sessionStorage.setItem("agency", agency);
  }

  getAgency() {
    // Retrieves the user agency from sessionStorage
    return sessionStorage.getItem("agency");
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }
}
